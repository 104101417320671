import { createRouter, createWebHistory } from "vue-router";

import Guard from '../services/middleware.vue'

const routes = [
  /*{
    path: '/maintenance', // Pode ser qualquer caminho que desejar
    name: 'Maintenance',
    component: () => import("@/views/error.vue"),
  },*/
  /*{
    path: '/convite/:slug', // Pode ser qualquer caminho que desejar
    name: 'Convites',
    component: () => import("@/views/invites/index.vue"),
  },*/
  {
    path: '/:slug', // Pode ser qualquer caminho que desejar
    name: 'Celebre Tudo Online',
    component: () => import("@/views/lists/page/index.vue"),
    children: [
      {
        path: "", // Rota vazia que vai redirecionar para "account"
        redirect: { name: "Início" }
      },
      {
        path: "",
        name: "Início",
        component: () => import("@/views/lists/page/home.vue"),
      },
      {
        path: "presentes",
        name: "Lista de Presentes",
        component: () => import("@/views/lists/page/gifts.vue"),
      },
      {
        path: "fotos",
        name: "Nossas Fotos",
        component: () => import("@/views/lists/page/photos.vue"),
      },
      {
        path: "recados",
        name: "Recadinhos",
        component: () => import("@/views/lists/page/comments.vue"),
      },
      {
        path: "eventos",
        name: "Eventos",
        component: () => import("@/views/lists/page/events.vue"),
      },
      {
        path: 'checkout/:uuid', // Pode ser qualquer caminho que desejar
        name: 'Celebre Tudo Online | Checkout',
        component: () => import("@/views/lists/page/checkout/index.vue"),
      },
      {
        path: 'pagamento/:uuid', // Pode ser qualquer caminho que desejar
        name: 'Celebre Tudo Online | Pagamento',
        component: () => import("@/views/lists/page/payment/index.vue"),
      },
      {
        path: 'obrigado/:uuid', // Pode ser qualquer caminho que desejar
        name: 'Obrigado(a) pela contribuição',
        component: () => import("@/views/lists/page/thankyou/index.vue"),
      },
    ]
  },
  {
    path: "/",
    name: "Login",
    component: () => import("@/views/auth/login/index.vue"),
  },
  {
    path: "/register",
    name: "Cadastro",
    component: () => import("@/views/auth/register/index.vue"),
  },
  {
    path: "/forgot",
    name: "Esqueceu senha",
    component: () => import("@/views/auth/forgot-password.vue"),
  },
  {
    path: "/reset-password/:id",
    name: "Recuperar senha",
    component: () => import("@/views/auth/reset-password.vue"),
  },
  {
    path: "/active/account/:token",
    name: "Ativar Conta",
    component: () => import("@/views/auth/active-account.vue"),
  },
  {
    path: "/app",
    name: "Dashboard",
    redirect: "/app/home",
    component: () => import("@/Layout/index.vue"),
    beforeEnter: Guard.auth,
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("@/views/home/dashboard.vue"),
      },
      {
        path: "list/:slug",
        name: "Festa",
        component: () => import("@/views/lists/handle/index.vue"),
        children: [
          {
            path: "", // Rota vazia que vai redirecionar para "account"
            redirect: { name: "Detalhes" }
          },
          {
            path: "details",
            name: "Detalhes",
            component: () => import("@/views/lists/handle/details.vue"),
          },
          {
            path: "gifts",
            name: "Presentes",
            component: () => import("@/views/lists/handle/gifts.vue"),
          },
          {
            path: "orders",
            name: "Contribuições",
            component: () => import("@/views/lists/handle/orders.vue"),
          },
          {
            path: "withdraws",
            name: "Resgates",
            component: () => import("@/views/lists/handle/withdraws.vue"),
          },
          {
            path: "themes",
            name: "Temas",
            component: () => import("@/views/lists/handle/themes.vue"),
          },
          {
            path: "photos",
            name: "Fotos",
            component: () => import("@/views/lists/handle/photos.vue"),
          },
          {
            path: "events",
            name: "Evento",
            component: () => import("@/views/lists/handle/events.vue"),
          },
          {
            path: "musics",
            name: "Música",
            component: () => import("@/views/lists/handle/audios.vue"),
          },
          {
            path: "invites",
            name: "Convidados",
            component: () => import("@/views/lists/handle/invites.vue"),
          },
          {
            path: "update",
            name: "Atualizando",
            component: () => import("@/views/lists/handle/update.vue")
          },
        ]
      },
      {
        path: "settings",
        name: "Configurações",
        component: () => import("@/views/settings/index.vue"),
        children: [
          {
            path: "", // Rota vazia que vai redirecionar para "account"
            redirect: { name: "Sobre" }
          },
          {
            path: "account",
            name: "Sobre",
            component: () => import("@/views/settings/account.vue"),
          },
          {
            path: "bank",
            name: "Banco",
            component: () => import("@/views/settings/bank.vue"),
          }
        ]
      },
      {
        path: '/steps', // Pode ser qualquer caminho que desejar
        name: 'Criando Festa',
        component: () => import("@/views/steps/index.vue"),
      },
    ],
  },
  {
    path: "/admin",
    name: "CRM Dashboard",
    redirect: "/admin/crm",
    component: () => import("@/Layout/Admin/index.vue"),
    beforeEnter: Guard.auth,
    children: [
      {
        path: "crm",
        name: "crm",
        component: () => import("@/views/admin/home/admin.vue"),
      },
      {
        path: "users",
        name: "users",
        component: () => import("@/views/admin/users/users.vue"),
      },
      {
        path: "lists/:userId",
        name: "lists",
        component: () => import("@/views/admin/lists/index.vue"),
      },
      {
        path: "withdraws/:slug",
        name: "withdraws",
        component: () => import("@/views/admin/lists/withdraws/index.vue"),
      },
      {
        path: "orders/:slug",
        name: "orders",
        component: () => import("@/views/admin/lists/orders/index.vue"),
      },
      {
        path: "gifts/:slug",
        name: "gifts",
        component: () => import("@/views/admin/lists/gifts/index.vue"),
      },
      {
        path: "invites/:slug",
        name: "invites",
        component: () => import("@/views/admin/lists/invites/index.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});
router.beforeEach((to, from, next) => {

  const routePath = to.path;

  let pageTitle = "Celebre Tudo";

  if (routePath.startsWith("/app/list/") && (routePath.endsWith("/details") || routePath.endsWith("/gifts") || routePath.endsWith("/orders")
    || routePath.endsWith("/photos") || routePath.endsWith("/themes") || routePath.endsWith("/invites") || routePath.endsWith("/musics") || routePath.endsWith("/withdraws") || routePath.endsWith("/update") || routePath.endsWith("/events")
  )) {
    // Extrair o slug da rota
    const slug = routePath.split("/app/list/")[1].split('/')[0];

    // Remover caracteres especiais e formatar o slug
    const formattedSlug = slug
      .replace(/[^a-zA-Z0-9-]/g, " ") // Remover caracteres especiais
      .split("-") // Dividir em palavras separadas por '-'
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalizar a primeira letra de cada palavra
      .join(" "); // Juntar as palavras com espaço

    let subRouteTitle = "";
    if (routePath.endsWith("/details")) {
      subRouteTitle = "Detalhes de";
    } else if (routePath.endsWith("/gifts")) {
      subRouteTitle = "Presentes de";
    } else if (routePath.endsWith("/orders")) {
      subRouteTitle = "Contribuições de";
    } else if (routePath.endsWith("/photos")) {
      subRouteTitle = "Fotos de";
    } else if (routePath.endsWith("/themes")) {
      subRouteTitle = "Tema de";
    } else if (routePath.endsWith("/withdraws")) {
      subRouteTitle = "Resgates de";
    } else if (routePath.endsWith("/events")) {
      subRouteTitle = "Evento de";
    } else if (routePath.endsWith("/musics")) {
      subRouteTitle = "Música de";
    } else if (routePath.endsWith("/invites")) {
      subRouteTitle = "Convidados de";
    } else if (routePath.endsWith("/update")) {
      subRouteTitle = "Atualizando";
    }


    // Adicionar o título personalizado à página
    pageTitle = `${subRouteTitle} ${formattedSlug} | Celebre Tudo`;
  } else if (routePath.includes("/") || (routePath.includes("/presentes") || routePath.includes("/fotos") || routePath.includes("/recados")
    || routePath.includes("/eventos") || routePath.includes("/checkout") || routePath.includes("/pagamento") || routePath.includes("/obrigado")
  )) {
    // Extrair o slug da rota
    const slug = routePath.split('/')[1];

    // Remover caracteres especiais e formatar o slug
    const formattedSlug = slug
      .replace(/[^a-zA-Z0-9-]/g, " ") // Remover caracteres especiais
      .split("-") // Dividir em palavras separadas por '-'
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalizar a primeira letra de cada palavra
      .join(" "); // Juntar as palavras com espaço

    let subRouteTitle = "";
    if (routePath.includes("/presentes")) {
      subRouteTitle = "Presentes para";
    } else if (routePath.includes("/")) {
      subRouteTitle = "Festa de";
    } else if (routePath.includes("/fotos")) {
      subRouteTitle = "Galeria de";
    } else if (routePath.includes("/recados")) {
      subRouteTitle = "Recados para";
    } else if (routePath.includes("/eventos")) {
      subRouteTitle = "Evento de";
    } else if (routePath.includes("/checkout")) {
      subRouteTitle = "Checkout de";
    } else if (routePath.includes("/pagamento")) {
      subRouteTitle = "Pagamento para";
    }


    // Adicionar o título personalizado à página
    pageTitle = `${subRouteTitle} ${formattedSlug} | Celebre Tudo`;
  } else if (routePath.endsWith("/inicio") || routePath.endsWith("/presentes")) {

    // Adicionar o título personalizado à página
    pageTitle = `Celebre Tudo`;
  } else {
    // Caso a rota não seja '/acao/:slug', use o título anterior
    const titleText = to.name;
    const words = titleText.split(" ");
    const wordslength = words.length;
    for (let i = 0; i < wordslength; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    pageTitle = words.join(" ") + " | Celebre Tudo";
  }

  document.title = pageTitle;
  next();
});
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});
export default router;
