import "animate.css";
//import "flatpickr/dist/flatpickr.css";
import "simplebar-vue/dist/simplebar-vue.js";
import "simplebar-vue/dist/simplebar.min.css";
import "simplebar/dist/simplebar.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import { createApp } from "vue";
import PrimeVue from 'primevue/config';
import Wind from './presets/wind';      
//import VueFlatPickr from "vue-flatpickr-component";
//import VueGoodTablePlugin from "vue-good-table-next";
//import "vue-good-table-next/dist/vue-good-table-next.css";
import VueSweetalert2 from "vue-sweetalert2";
//import VueTippy from "vue-tippy";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
//import VueApexCharts from "vue3-apexcharts";
import VueClickAway from "vue3-click-away";
import App from "./App.vue";
import VueConfetti from 'vue-confetti';
import "./assets/scss/auth.scss";
import "./assets/scss/tailwind.scss";
import router from "./router";
import store from "./store";

import 'primevue/resources/themes/aura-light-green/theme.css'
import "primevue/resources/primevue.min.css"; /* Deprecated */
import "primeicons/primeicons.css";
import "./assets/flags.css";
import ConfirmationService from 'primevue/confirmationservice';
import backdrop from "./plugins/backdrop";
import './assets/css/fonts.css'; // Importe o arquivo CSS aqui

import vue3GoogleLogin from 'vue3-google-login'
const googleClientId = '53551267054-ooh10ha4ur98qa3pgi5orei2lq45dibh.apps.googleusercontent.com';

// check localStorage theme for dark light bordered
if (localStorage.theme === "dark") {
  document.body.classList.add("dark");
  store.state.theme = "dark";
  store.state.isDark = true;
} else {
  document.body.classList.add("light");
  store.state.theme = "light";
  store.state.isDark = false;
}
import { createI18n } from 'vue-i18n';

const i18n = createI18n({
  locale: 'en', // definir o idioma padrão aqui
  messages: {
    en: {
      "card": {
        "cardHolder": "Titular Cartão",
        "fullName": "Nome Completo",
        "expires": "Expira",
        "MM": "MM",
        "YY": "AA"
      },
      "cardForm": {
        "cardNumber": "Numero do Cartão",
        "cardName": "Nome Titular",
        "expirationDate": "Expira em",
        "month": "Mês",
        "year": "Ano",
        "CVV": "CVV",
        "submit": "Finalizar agora",
        "buyerCPF": "CPF do titular",
        "installmentsNumber": "Nº de parcelas",
        "invalidCardNumber": "Número do Cartão Inválido",
        "selectInstallments": "Selecione ..."
      }
    },
    // adicione traduções para outros idiomas, se desejar
  }
});
// vue use
createApp(App)
  .use(vue3GoogleLogin, { clientId: googleClientId })
  .use(store)
  .use(VueSweetalert2)
  .use(ConfirmationService)
  .use(Toast, {
    toastClassName: "dashcode-toast",
    bodyClassName: "dashcode-toast-body",
  })
  .use(VueConfetti)
  .use(router)
  .use(VueClickAway)
  .use(PrimeVue, {

    pt: Wind
  })
  .use(backdrop)
  .use(i18n)
  .mount("#app");
